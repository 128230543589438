import React from "react";

const ShopdeskIcon = () => {
  return (
    <>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="40" height="40" rx="6" fill="#262626" />
        <path
          d="M10.4 9.99219C9.84774 9.99219 9.40002 10.4399 9.40002 10.9922C9.40002 11.5445 9.84774 11.9922 10.4 11.9922V9.99219ZM12.6926 10.9922L13.6578 10.7308C13.5397 10.2949 13.1442 9.99219 12.6926 9.99219V10.9922ZM16.4179 24.7474L15.4527 25.0088C15.583 25.4898 16.0475 25.8015 16.542 25.7397L16.4179 24.7474ZM27.8806 23.3146L28.0047 24.3069C28.4293 24.2538 28.7732 23.9363 28.8599 23.5172L27.8806 23.3146ZM29.6 15.0041L30.5793 15.2067C30.6402 14.9122 30.5654 14.6059 30.3755 14.3727C30.1856 14.1395 29.9008 14.0041 29.6 14.0041V15.0041ZM13.7791 15.0041L12.8139 15.2655L13.7791 15.0041ZM10.4 11.9922H12.6926V9.99219H10.4V11.9922ZM16.542 25.7397L28.0047 24.3069L27.7566 22.3223L16.2939 23.7551L16.542 25.7397ZM28.8599 23.5172L30.5793 15.2067L28.6208 14.8015L26.9014 23.112L28.8599 23.5172ZM11.7273 11.2536L12.8139 15.2655L14.7444 14.7427L13.6578 10.7308L11.7273 11.2536ZM12.8139 15.2655L15.4527 25.0088L17.3832 24.486L14.7444 14.7427L12.8139 15.2655ZM29.6 14.0041H13.7791V16.0041H29.6V14.0041ZM19 28.4995C19 28.7756 18.7762 28.9995 18.5 28.9995V30.9995C19.8807 30.9995 21 29.8802 21 28.4995H19ZM18.5 28.9995C18.2239 28.9995 18 28.7756 18 28.4995H16C16 29.8802 17.1193 30.9995 18.5 30.9995V28.9995ZM18 28.4995C18 28.2233 18.2239 27.9995 18.5 27.9995V25.9995C17.1193 25.9995 16 27.1187 16 28.4995H18ZM18.5 27.9995C18.7762 27.9995 19 28.2233 19 28.4995H21C21 27.1187 19.8807 25.9995 18.5 25.9995V27.9995ZM27 28.4995C27 28.7756 26.7762 28.9995 26.5 28.9995V30.9995C27.8807 30.9995 29 29.8802 29 28.4995H27ZM26.5 28.9995C26.2239 28.9995 26 28.7756 26 28.4995H24C24 29.8802 25.1193 30.9995 26.5 30.9995V28.9995ZM26 28.4995C26 28.2233 26.2239 27.9995 26.5 27.9995V25.9995C25.1193 25.9995 24 27.1187 24 28.4995H26ZM26.5 27.9995C26.7762 27.9995 27 28.2233 27 28.4995H29C29 27.1187 27.8807 25.9995 26.5 25.9995V27.9995Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default ShopdeskIcon;
