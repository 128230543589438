import React, { useState, useEffect } from "react"
import { AppstoreOutlined, DownOutlined, UpOutlined } from "@ant-design/icons"
import { Dropdown, Menu, Tabs } from "antd"
import DropdownComponent from "./DropdownComponent"
import { options } from "../../../utils/constants"

const CommonHeader = () => {
  const [tabsKey, setTabsKey] = useState()
  const [moduleValue, setModuleValue] = useState({
    text: "Select Module",
  })

  const onChange = (key: any) => {
    setTabsKey(key)
  }

  const menus = (
    <Menu>
      <Menu.Item>
        <div
          className="rec-box z-10"
          style={{
            position: "absolute",
            width: "20px",
            height: "20px",
            right: "75px",
            top: "14px",
            background: "#f7f7f7",
            borderTop: "1px solid rgba(229, 231, 235, var(--tw-border-opacity))",
            borderLeft: "1px solid rgba(229, 231, 235, var(--tw-border-opacity))",
            transform: "matrix(0.69, 0.72, -0.69, 0.72, 0, 0)",
          }}
        ></div>
        <div className="dropdown-tabs bg-white absolute top-6 border border-solid border-slate-300 rounded-t-lg overflow-hidden">
          <Tabs defaultActiveKey="1" onChange={onChange} className="modules-tabs">
            <Tabs.TabPane tab={"All Modules"} key={"1"}>
              <DropdownComponent tabKey={"2"} />
              <DropdownComponent tabKey={"3"} />
              <DropdownComponent tabKey={"4"} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={"Commerce Solutions"} key={"2"}>
              <DropdownComponent tabKey={tabsKey} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={"Marketing Solutions"} key={"3"}>
              <DropdownComponent tabKey={tabsKey} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={"Payment Solutions"} key={"4"}>
              <DropdownComponent tabKey={tabsKey} />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </Menu.Item>
    </Menu>
  )

  useEffect(() => {
    const currentUrl = window?.location?.href;

    const matchedOption = options.find(option => currentUrl?.includes(option.suburl));

    if (matchedOption) {
      setModuleValue({text: matchedOption?.text});
    }
  }, [])

  return (
    <>
      <Dropdown
        overlay={menus}
        className="w-full h-full text-[0.8vw] text-white bg-transparent font-bold"
        trigger={["click"]}
      >
        <a
          href="#"
          className="flex items-center text-white"
          onClick={(e) => e.preventDefault()}
        >
          <AppstoreOutlined
            style={{ color: "black", fontSize: "15px", marginRight: "5px" }}
          />
          <div className="flex flex-col text-white pr-[0.5vw] justify-center">
            <p className="m-0 text-black text-[1.2vw]">
              <div className="module-app">
                {moduleValue?.text}
              </div>
            </p>
          </div>
          <div className="flex flex-col text-white pr-[0.5vw] justify-center">
            <UpOutlined style={{ color: "black", fontSize: "6px" }} />
            <DownOutlined style={{ color: "black", fontSize: "6px" }} />
          </div>
        </a>
      </Dropdown>
    </>
  )
}

export default CommonHeader
