import React from "react";

function XstakLogoTextIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="122"
      height="40"
      fill="none"
      viewBox="0 0 122 40"
    >
      <path
        fill="#7E62D1"
        d="M25.193 1.484H16.8v37.144h8.393V1.484z"
        opacity="0.6"
      ></path>
      <path
        fill="#7E62D1"
        d="M39.569 15.828H2.424v8.393H39.57v-8.393z"
        opacity="0.8"
      ></path>
      <path
        fill="#7E62D1"
        d="M10.853 3.947L4.918 9.882l26.265 26.265 5.935-5.935L10.853 3.947z"
      ></path>
      <path
        fill="#7E62D1"
        d="M31.183 3.902L4.918 30.167l5.935 5.934L37.118 9.838l-5.935-5.935z"
      ></path>
      <path
        fill="#679CE0"
        d="M25.186 1.472h-8.388v5.87h8.388v-5.87zM10.859 3.948L4.934 9.886l4.156 4.146 5.925-5.937-4.156-4.147zM32.964 26.051l-5.925 5.938 4.156 4.147 5.925-5.938-4.156-4.147zM31.178 3.916l-4.156 4.147L32.946 14l4.156-4.147-5.924-5.938zM9.07 26.022l-4.155 4.146 5.924 5.938 4.156-4.146-5.924-5.938zM25.186 32.782h-8.388v5.87h8.388v-5.87zM8.3 15.834H2.43v8.388H8.3v-8.388zM39.58 15.834h-5.87v8.388h5.87v-8.388z"
      ></path>
      <path
        fill="#fff"
        d="M52.432 17.558l-4.117-6.14h-3.41v.142l5.55 7.997-5.715 8.232v.094h3.481l4.21-6.186 4.21 6.186h3.482v-.094l-5.74-8.232 5.575-7.997v-.141h-3.41l-4.116 6.139zM74.59 14.123c-1.246-2.54-3.904-3.269-6.468-3.269-3.034.024-6.374 1.411-6.374 4.798 0 3.693 3.105 4.587 6.468 4.987 2.188.235 3.81.87 3.81 2.422 0 1.788-1.834 2.47-3.786 2.47-2 0-3.905-.8-4.634-2.61l-2.587 1.34c1.223 3.01 3.81 4.046 7.174 4.046 3.669 0 6.915-1.576 6.915-5.245 0-3.928-3.2-4.822-6.633-5.246-1.976-.235-3.67-.635-3.67-2.07 0-1.223 1.106-2.187 3.411-2.187 1.788 0 3.34.894 3.905 1.835l2.47-1.27zM78.727 13.324v2.987h-2.164v2.47h2.164v5.01c0 2.893 1.647 4.328 4.163 4.233a6.412 6.412 0 002.376-.494l-.8-2.446c-.423.212-.94.353-1.387.353-.894 0-1.506-.54-1.506-1.646v-5.01h3.223v-2.47h-3.2v-3.293l-2.869.306zM96.048 17.887c-.658-1.153-2.14-1.882-3.692-1.882-3.387-.023-6.045 2.07-6.045 6.069 0 4.069 2.54 6.186 5.974 6.162 1.294-.023 3.105-.682 3.763-2.046l.141 1.693h2.705V16.287h-2.752l-.094 1.6zm-3.434.682c4.516 0 4.516 7.033 0 7.033-1.905 0-3.434-1.317-3.434-3.528 0-2.211 1.53-3.505 3.434-3.505zM101.254 11.442v16.441h2.87v-5.245l4.539 5.245h3.458v-.211l-5.386-5.928 4.727-5.292v-.165h-3.434l-3.904 4.658v-9.503h-2.87zM115.6 11.2c.471 0 .872.166 1.202.496.336.33.504.73.504 1.202 0 .47-.167.874-.504 1.21-.33.33-.731.495-1.202.495s-.871-.165-1.202-.495a1.652 1.652 0 01-.503-1.21c0-.472.167-.872.503-1.202.331-.33.731-.496 1.202-.496zm.967.74a1.318 1.318 0 00-.967-.399c-.373 0-.693.133-.958.398a1.308 1.308 0 00-.398.959c0 .379.133.701.398.966s.585.398.958.398c.379 0 .702-.133.967-.398s.398-.587.398-.966c0-.374-.133-.694-.398-.959zm-1.519 1.908V12.02h.885c.092 0 .171.033.236.098a.322.322 0 01.098.236v.6c0 .093-.033.171-.098.237a.287.287 0 01-.146.089l.333.569h-.398l-.527-.902h.495v-.585h-.536v1.487h-.342z"
      ></path>
    </svg>
  );
}

export default XstakLogoTextIcon;
