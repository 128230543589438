export const options = [
  // {
  //   id: 2,
  //   link: '/hypr/dashboard',
  //   suburl: '/hypr',
  //   imgSrc: '/logos/HYPR.svg',
  //   altText: 'hyper',
  //   type: 'hypr',
  //   heading: 'Commerce Solutions',
  //   text: 'Headless Hypr Local Commerce',
  //   permission: 'hypr',
  //   module: 'commerce',
  //   xstakURL: 'https://www.xstak.com/hypr',
  // },
  {
    id: 3,
    link: '/oe/dashboard',
    suburl: '/oe',
    imgSrc: '/logos/OE.svg',
    altText: 'OE',
    type: 'oe',
    heading: 'Commerce Solutions',
    text: 'Inventory & Order Management',
    permission: 'oe',
    module: 'commerce',
    xstakURL: 'https://www.xstak.com/omnichannel-engine',
  },
  {
    id: 4,
    link: '/shopdesk/brands',
    suburl: '/shopdesk',
    imgSrc: '/logos/SD.svg',
    altText: 'shopdesk',
    type: 'shopdesk',
    heading: 'Commerce Solutions',
    text: 'Point Of Sale',
    permission: 'sd',
    module: 'commerce',
    xstakURL: 'https://www.xstak.com/shopdesk',
  },
  {
    id: 5,
    link: '/or/dashboard',
    suburl: '/or',
    imgSrc: '/logos/OR.svg',
    altText: 'omniretain',
    type: 'or',
    heading: 'Marketing Solutions',
    text: ' Loyalty Management',
    permission: 'or',
    module: 'marketing',
    xstakURL: 'https://www.xstak.com/omniretain',
  },
  {
    id: 6,
    link: '/xpay/dashboard',
    suburl: '/xpay',
    imgSrc: '/logos/Xpay.svg',
    altText: 'xstak pay',
    type: 'xpay',
    heading: 'Payment Solutions',
    text: 'Payment Gateway Solution',
    permission: 'xpay',
    module: 'payment',
    xstakURL: 'https://www.xstak.com/omnichannel-payments',
  },
  {
    id: 7,
    link: '/rfm/marketing',
    suburl: '/rfm',
    imgSrc: '/logos/logo512.png',
    altText: 'Customer Data Platform',
    type: 'cdp',
    heading: 'Marketing Solutions',
    text: 'Customer Data Platform',
    permission: 'oe',
    module: 'marketing',
    xstakURL: 'https://www.xstak.com/omnichannel-payments',
  },
];
