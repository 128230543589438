import React from "react";

const CustomerDataPlatform = () => {
  return (
    <>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="40" height="40" rx="6" fill="#262626" />
        <path
          d="M10.4 26.2168C11.4987 24.3843 13.9065 23.1301 17.6631 23.1301C21.4197 23.1301 23.8276 24.3843 24.9263 26.2168M25.6213 21.7406C27.6106 22.7352 28.6053 23.7299 29.6 25.7193M23.5522 14.1376C24.4883 14.6412 25.1246 15.6299 25.1246 16.7673C25.1246 17.8718 24.5246 18.8362 23.6326 19.3522M20.6472 16.7673C20.6472 18.4153 19.3112 19.7513 17.6631 19.7513C16.0151 19.7513 14.6791 18.4153 14.6791 16.7673C14.6791 15.1192 16.0151 13.7832 17.6631 13.7832C19.3112 13.7832 20.6472 15.1192 20.6472 16.7673Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </>
  );
};

export default CustomerDataPlatform;
