import React from "react";

const OmniChannelIcon = () => {
  return (
    <>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="40" height="40" rx="6" fill="#262626" />
        <path
          d="M27.6708 15.2004L19.3569 10.4004L11.0431 15.2004V24.8004L19.3569 29.6004M27.6708 15.2004L19.3569 20.6004M27.6708 15.2004V20.0004M19.3569 29.6004V20.6004M19.3569 29.6004L21.1564 28.5615M19.3569 20.6004L11.5569 15.8004M22.9569 17.6004L15.1569 12.8004M24.1569 26.0004L25.3569 27.2004L28.9569 23.6004"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default OmniChannelIcon;
