import React, { useState, useEffect } from "react"
import { MobileHeader } from "./features/header/components/MobileHeader"
import useWindowSize from "./lib/custom-hooks/useWindowSize"
import "./index.css"
import "./menu.css"
import "./dropdown.css"
import { Drawer } from "../Drawer"

export type NavBarProps = {
  menu?: any
  openKeys?: any
  selectedKeys?: string[]
  handleOpenSubMenu?: any
  app?: string
  doCollapse?: any
  location?: any
  extra?: React.ReactNode
  children?: JSX.Element
}

export const NavBar = ({
  openKeys,
  selectedKeys,
  handleOpenSubMenu,
  menu,
  app,
  doCollapse,
  location,
  extra,
  children,
}: NavBarProps) => {
  const size = useWindowSize()
  const [fullScreenMenu, setFullScreenMenu] = useState(size[0] <= 768 ? false : true)
  const [collapsed, setCollapsed] = useState<boolean>(size[0] > 768 ? false : true)

  useEffect(() => {
    if (size[0] <= 768 || doCollapse) {
      if (fullScreenMenu) return
      setCollapsed(true)
    } else {
      setCollapsed(false)
      setFullScreenMenu(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size, location])

  const handleSideBarCollapse = (isArrowClicked = false) => {
    // alert(isArrowClicked)
    if (isArrowClicked === true) {
      setCollapsed(!collapsed)
      if (size[0] <= 768) {
        setFullScreenMenu(!fullScreenMenu)
      }
    } else {
      if (size[0] <= 768) {
        setCollapsed(!collapsed)
        setFullScreenMenu(!fullScreenMenu)
      }
    }
  }

  return (
    <>
      {size[0] > 768 ? (
        <></>
      ) : (
        // <Header />
        <MobileHeader
          collapsed={collapsed}
          handleSideBarCollapse={handleSideBarCollapse}
          menu={menu}
          openKeys={openKeys}
          selectedKeys={selectedKeys}
          handleOpenSubMenu={handleOpenSubMenu}
          app={app}
          extra={extra}
        />
      )}
      <Drawer
        openKeys={openKeys}
        selectedKeys={selectedKeys}
        handleOpenSubMenu={handleOpenSubMenu}
        menu={menu}
        app={app}
        doCollapse={doCollapse}
        location={location}
        fullScreenMenu={fullScreenMenu}
        setFullScreenMenu={setFullScreenMenu}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        handleSideBarCollapse={handleSideBarCollapse}
        extra={extra}
        children={children}
      />
    </>
  )
}
