import React from "react";

const OmniRetainIcon = () => {
  return (
    <>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="40" height="40" rx="6" fill="#262626" />
        <path
          d="M20.1746 29.653V15.6894M18.5496 14.8517C18.7224 14.8905 18.9046 14.8429 19.0291 14.7185C19.1535 14.594 19.201 14.4118 19.1623 14.239C19.0071 13.6052 18.4356 11.483 17.8487 10.8961C17.1176 10.1649 15.9276 10.1619 15.2 10.8894C14.4725 11.6169 14.4755 12.8069 15.2067 13.5381C15.8032 14.1346 17.9158 14.6966 18.5496 14.8517ZM20.3242 14.2389C20.2854 14.4118 20.3329 14.594 20.4574 14.7184C20.5818 14.8429 20.7641 14.8904 20.9369 14.8517C21.5706 14.6965 23.6929 14.125 24.2797 13.5381C25.0109 12.8069 25.014 11.6169 24.2864 10.8894C23.5589 10.1619 22.3689 10.1648 21.6377 10.8961C21.0412 11.4925 20.4793 13.6052 20.3242 14.2389ZM11.0982 20.5767H28.9018C29.2874 20.5767 29.6 20.2641 29.6 19.8785V16.3876C29.6 16.002 29.2874 15.6894 28.9018 15.6894H11.0982C10.7126 15.6894 10.4 16.002 10.4 16.3876V19.8785C10.4 20.2641 10.7126 20.5767 11.0982 20.5767ZM27.8546 20.5767V28.9548C27.8546 29.3404 27.542 29.653 27.1564 29.653H12.8437C12.4581 29.653 12.1455 29.3404 12.1455 28.9548V20.5767H27.8546Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default OmniRetainIcon;
