import React from "react"
import Products from "../../../../Drawer/components/Products"

const DropdownComponent = (tabKey: any) => {
  let heading = "All Modules"
  if (tabKey?.tabKey == "2") {
    heading = "Commerce Solutions"
  } else if (tabKey?.tabKey == "3") {
    heading = "Marketing Solutions"
  } else if (tabKey?.tabKey == "4") {
    heading = "Payment Solutions"
  }
  return (
    <div className="dashboard">
      <div className="dashboard__text">
        {
          <h4
            className="h3"
            style={{ color: "#7e62d1", fontSize: "16px", fontWeight: "600" }}
          >
            {heading}
          </h4>
        }
      </div>
      <Products tabKey={tabKey} heading={heading} />
    </div>
  )
}

export default DropdownComponent
