import React from "react"

import useWindowSize from "../NavBar/lib/custom-hooks/useWindowSize"
import { SideBar } from "./components/SideBar"

export type MenuInterface = {
  url: string
  icon?: React.ReactNode
  wholeLink?: React.ReactNode
  title?: string
  disabled?: boolean
  permissionCode?: Array<string>
}

export type SingleMenu = {
  subMenues?: Array<MenuInterface>
} & MenuInterface

type DrawerProps = {
  openKeys?: any
  selectedKeys?: any
  handleOpenSubMenu?: any
  menu?: any
  app?: string
  doCollapse?: any
  location?: any
  fullScreenMenu?: boolean
  setFullScreenMenu?: Function
  collapsed?: boolean
  setCollapsed?: Function
  handleSideBarCollapse?: any
  extra?: React.ReactNode
  children?: JSX.Element
}

import "./assets/SideBar.scss"
import "./assets/SideBar.css"
import LeftRightArrows from "../icons/arrows"
import { Header } from "../NavBar/features/header/components/Header"

export const Drawer = ({
  openKeys,
  selectedKeys,
  handleOpenSubMenu,
  menu,
  app,
  doCollapse,
  location,
  fullScreenMenu,
  setFullScreenMenu,
  collapsed,
  setCollapsed,
  handleSideBarCollapse,
  extra,
  children,
}: DrawerProps) => {
  const size = useWindowSize()
  return (
    <>
      <div
        className={`side_bar_wrapper ${fullScreenMenu ? "full-menu-open" : ""}`}
        style={
          size[0] > 768
            ? { position: "relative", width: "100%", display: "flex" }
            : fullScreenMenu
            ? { width: "100%" }
            : {}
        }
      >
        <div
          className={`sidebar_div  bg-black ${
            fullScreenMenu ? "full-size-sidebar flex h-screen " : ""
          }`}
        >
          <SideBar
            width={size[0] <= 768 ? "100%" : 260}
            isCollapsible
            header=""
            menues={menu}
            location={location}
            showFullScreen={fullScreenMenu}
            collapsed={collapsed}
            onClickOption={handleSideBarCollapse}
            openKeys={openKeys}
            selectedKeys={selectedKeys}
            handleOpenSubMenu={handleOpenSubMenu}
            app={app}
          />
          {size[0] > 768 ? (
            <>
              <div
                className={collapsed ? "hideCollapse" : "showCollapse"}
                style={{
                  position: "absolute",
                  right: "0",
                  top: "60px",
                  zIndex: "100",
                  transform: "translateX(50%)",
                  background: "#e8e8e8",
                  borderRadius: "20px",
                  padding: "4px",
                  paddingLeft: "6px",
                  paddingBlock: "6px",
                  cursor: "pointer",
                }}
                onClick={() => handleSideBarCollapse(true)}
              >
                <LeftRightArrows className={collapsed ? "collapsed" : "full-size"} />
              </div>
            </>
          ) : null}
        </div>
        {size[0] > 768 ? (
          <div className="main-page block w-full bg-[#f0f2f5] overflow-auto overflow-y-hidden">
            <div className="main-page-one">
              <Header extra={extra} />
            </div>
            <div className="main-page-two">{children}</div>
          </div>
        ) : (
          children
        )}
      </div>
    </>
  )
}
