import React from "react";

const XpayIcon = () => {
  return (
    <>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="40" height="40" rx="6" fill="#262626" />
        <path
          d="M20.0001 25.8117L12.2004 25.8116C10.8749 25.8115 9.80047 24.7371 9.80043 23.4116L9.80017 14.4118C9.80013 13.0863 10.8747 12.0117 12.2002 12.0117H26.5991C27.9248 12.0117 28.9997 13.0859 28.9997 14.4116L28.9997 18.2117M10.3997 16.2116H28.3997M24.1997 21.4866L25.5897 20.7916C26.6032 20.2849 27.7962 20.2849 28.8097 20.7916L30.1997 21.4866C30.1997 21.4866 30.1997 23.6766 30.1997 24.8316C30.1997 25.9866 29.132 26.7663 27.1997 27.9866C25.2674 26.7663 24.1997 25.7366 24.1997 24.8316V21.4866Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default XpayIcon;
