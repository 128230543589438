// import { useAuth } from '@/lib/auth';
import React from "react"

import { UserOutlined } from "@ant-design/icons"
import { Menu, Dropdown } from "antd"
import CommonHeader from "./desktopMenu"
type extra = {
  extra?: React.ReactNode
}
export const Header = ({ extra }: extra) => {
  const logout = () => {
    window.localStorage.clear()
    window.sessionStorage.clear()
    window.location.assign("/auth/login")
  }

  const name = JSON.parse(window.localStorage.getItem("name") as string)
  const profile = (
    <Menu className="logout-position flex flex-col">
      <Menu.Item>{name ?? "Admin"}</Menu.Item>
      <Menu.Item
        danger
        onClick={() => {
          window.sessionStorage.removeItem("module")
          logout()
        }}
        className="header_logout_btn"
      >
        <button>Log out</button>
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      <div
        className="w-full bg-white h-[4vw] flex items-center px-[0.4vw] sticky top-0 z-50"
        style={{
          borderBottom: "1px solid #f0f0f0",
        }}
      >
        <div
          className={`module-opt rounded h-[2.75vw] px-[0.25vw] items-center flex ml-[1.25vw] bg-[#f0f0f0]`}
        >
          {/* <div className="rounded-full opacity-50 w-[2.7vw] h-[2.1vw] flex justify-center items-center"></div> */}
          <CommonHeader />
        </div>
        <div className="flex items-center ml-auto">
          {extra}
          <div className="flex flex-row items-center rounded  p-[0.25rem] mr-[1vw] administrator_box">
            {/* insert user image here */}
            <Dropdown overlay={profile} trigger={["click"]} className="flex">
              <a
                href="#"
                className="flex items-center text-white"
                onClick={(e) => e.preventDefault()}
              >
                <UserOutlined className="rounded-full overflow-hidden mr-[0.625vw] !text-[2vw] max-w-[2vw] max-h-[3vw]  !text-white bg-black" />
              </a>
            </Dropdown>
          </div>
        </div>
      </div>
    </>
  )
}
